import * as React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useEffect, useState, useMemo } from 'react'
import { PaletteOptions } from '@mui/material/styles/createPalette'

const mediaQuery = '(prefers-color-scheme: dark)'

function hasMatchMedia(): boolean {
  return typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'
}

function isDarkMode(): boolean {
  if (hasMatchMedia()) {
    return window.matchMedia(mediaQuery).matches
  }
  return true
}

const RootWrapper: React.FC = ({ children }: React.PropsWithChildren) => {
  const [systemDarkMode, setSystemDarkMode] = useState(() => isDarkMode())

  // Set up a watcher on the media query if availabe
  useEffect(() => {
    if (hasMatchMedia()) {
      const matchedMedia = window.matchMedia(mediaQuery)
      if (matchedMedia.addEventListener !== undefined) {
        matchedMedia.addEventListener('change', () => {
          setSystemDarkMode(isDarkMode())
        })
      }
    }
  }, [])

  const theme = useMemo(() => {
    const palette: PaletteOptions = {
      mode: 'dark',
      primary: {
        light: '#52c7b8',
        dark: '#00675b',
        main: '#009688',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#7a6a56',
        dark: '#251903',
        main: '#4d3f2d',
        contrastText: '#ffffff'
      },
      error: {
        light: '#ffa538',
        main: '#ff910a',
        dark: '#e07b00'
      },
      background: {
        default: '#171717'
      }
    }

    if (!systemDarkMode) {
      palette.mode = 'light'
      palette.background = {
        default: '#f5f5f5'
      }
    }

    return createTheme({ palette })
  }, [systemDarkMode])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet" />
      </Helmet>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default RootWrapper

import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material'
import * as React from 'react'
import { Link } from 'gatsby'
import MenuIcon from '@mui/icons-material/Menu'
import MailIcon from '@mui/icons-material/Email'
import BookIcon from '@mui/icons-material/MenuBook'
import { FunctionComponent, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Apps from '@mui/icons-material/Apps'
import { AkhetContainer } from './components/akhet-container'

interface PageProps {
  location: Location
  data: ImageData
}

export const PageWrapper: FunctionComponent<PageProps> = ({
  children,
  location
}: React.PropsWithChildren<PageProps>) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()

  const title = 'Akhet Tech, LLC'

  const currentTab = React.useMemo(() => {
    if (location.pathname.includes('/about')) {
      return 1
    } else if (location.pathname.includes('/contact')) {
      return 2
    } else if (location.pathname === '/') {
      return 0
    }
    return false
  }, [location])

  function toggleDrawer(): void {
    setDrawerOpen(!drawerOpen)
  }

  function closeDrawer(): void {
    setDrawerOpen(false)
  }

  return (
    <AkhetContainer disableGutters>
      <AppBar
        position="fixed"
        sx={{
          [theme.breakpoints.up('sm')]: {
            display: 'none'
          }
        }}
        color="secondary"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              [theme.breakpoints.up('sm')]: {
                display: 'none'
              }
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography align="center" variant="h6" style={{ width: '100%' }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0
        }}
        variant="temporary"
        open={drawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <Box
          sx={{
            ...theme.mixins.toolbar,
            [theme.breakpoints.up('sm')]: { display: 'none' }
          }}
        />
        <Divider />
        <List>
          <ListItem button component={Link} to="/" selected={location.pathname === '/'} onClick={closeDrawer}>
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/about"
            selected={location.pathname.includes('/about')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/contact"
            selected={location.pathname.includes('/contact')}
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItem>
        </List>
      </Drawer>
      <main>
        <Hidden smUp>
          <Box
            sx={{
              ...theme.mixins.toolbar
            }}
          />
        </Hidden>
        <Box position="relative">
          <StaticImage
            src="./images/wp-header.jpg"
            alt={title}
            style={{
              display: 'block!important',
              minHeight: 150
            }}
            layout="constrained"
            loading="eager"
            quality={100}
          />
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,0.2)',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
          />
          <Hidden smDown>
            <Typography
              variant="h2"
              sx={{
                color: '#fff',
                position: 'absolute',
                top: 50,
                left: 40,
                fontWeight: 500
              }}
            >
              {title}
            </Typography>
          </Hidden>
        </Box>
        <Hidden smDown>
          <AppBar
            position="relative"
            sx={{
              zIndex: 1
            }}
            color="secondary"
          >
            <Tabs value={currentTab} centered indicatorColor="primary">
              <Tab component={Link} to="/" label="Products" />
              <Tab component={Link} to="/about" label="About" />
              <Tab component={Link} to="/contact" label="Contact" />
            </Tabs>
          </AppBar>
        </Hidden>
        <Box paddingY={2}>{children}</Box>
      </main>
    </AkhetContainer>
  )
}
